import React from 'react';

import {
  ChangeProductsFiltersFunc,
  FetchProductsFilters
} from '../../../../../productsTypes';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { PureIconButtonHelper } from '../../../../../../../helpers/buttons/PureIconButtonHelper';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import {
  ProductsCategoriesFilter,
  ProductsCategoriesFilterCategory,
  ProductsCategoriesFilterSetParentCategory
} from '../../../../filters/ProductsCategoriesFilter';
import { ProductsBrandsFilter } from '../../../../filters/ProductsBrandsFilter';
import { ProductsStylesFilter } from '../../../../filters/ProductsStylesFilter';
import { ProductsMaterialsFilter } from '../../../../filters/ProductsMaterialsFilter';
import { ProductsColorsFilter } from '../../../../filters/ProductsColorsFilter';
import { ProductsNdaFilter } from '../../../../filters/ProductsNdaFilter';
import { ProductsClientFilter } from '../../../../filters/ProductsClientFilter';
import { ProductsBlockedFilter } from '../../../../filters/ProductsBlockedFilter';
import { ProductsMyLibraryFilter } from '../../../../filters/ProductsMyLibraryFilter';
import { ProductsFavoriteCheckboxFilter } from '../../../../filters/ProductsFavoriteCheckboxFilter';

import { ProductsPermissions } from '../../../../../productsConstants';

interface SelectProductsContentFiltersSidebarProps {
  parentCategory: ProductsCategoriesFilterCategory;
  setParentCategory: ProductsCategoriesFilterSetParentCategory;
  productsFilters: FetchProductsFilters;
  changeProductsFilters: ChangeProductsFiltersFunc;
  onClose: () => void;
}

function SelectProductsContentFiltersSidebar({
  parentCategory,
  setParentCategory,
  productsFilters,
  changeProductsFilters,
  onClose
}: SelectProductsContentFiltersSidebarProps) {
  return (
    <div className="flex flex-col w-80 4xl:w-112 absolute xl:relative bg-white dark:bg-gray-850 border-r dark:border-gray-700 left-0 top-0 bottom-0 max-h-full max-w-full z-10">
      <div className="flex flex-col max-h-full flex-1 overflow-y-auto py-4">
        <PureIconButtonHelper
          className="py-2 pl-2 pr-2 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 z-20 absolute top-1 right-3.5 text-gray-600 dark:text-gray-400 hover:shadow-md bg-white dark:bg-gray-900"
          icon={IconsEnum.X_OUTLINE}
          onClick={onClose}
        />
        <div id="nav-3d-stock">
          <ProductsCategoriesFilter
            parentCategory={parentCategory}
            setParentCategory={setParentCategory}
            changeProductsFilters={changeProductsFilters}
          />

          <CheckPermissions
            action={ProductsPermissions.READ_PRODUCTS_MY_LIBRARY_FILTER}
          >
            <ProductsMyLibraryFilter
              productsFilters={productsFilters}
              changeProductsFilters={changeProductsFilters}
            />
          </CheckPermissions>

          <CheckPermissions
            action={ProductsPermissions.READ_PRODUCTS_FAVORITE_CHECKBOX_FILTER}
          >
            <ProductsFavoriteCheckboxFilter
              productsFilters={productsFilters}
              changeProductsFilters={changeProductsFilters}
            />
          </CheckPermissions>

          <CheckPermissions
            action={ProductsPermissions.READ_PRODUCTS_NDA_FILTER}
          >
            <ProductsNdaFilter
              productsFilters={productsFilters}
              changeProductsFilters={changeProductsFilters}
            />
          </CheckPermissions>

          <CheckPermissions
            action={ProductsPermissions.READ_PRODUCTS_CLIENTS_FILTER}
          >
            <ProductsClientFilter
              productsFilters={productsFilters}
              changeProductsFilters={changeProductsFilters}
            />
          </CheckPermissions>

          <ProductsStylesFilter
            productsFilters={productsFilters}
            changeProductsFilters={changeProductsFilters}
          />

          <CheckPermissions
            action={ProductsPermissions.READ_PRODUCTS_MATERIALS_FILTER}
          >
            <ProductsMaterialsFilter
              productsFilters={productsFilters}
              changeProductsFilters={changeProductsFilters}
            />
          </CheckPermissions>

          <CheckPermissions
            action={ProductsPermissions.READ_PRODUCTS_COLORS_FILTER}
          >
            <ProductsColorsFilter
              productsFilters={productsFilters}
              changeProductsFilters={changeProductsFilters}
            />
          </CheckPermissions>

          <CheckPermissions
            action={ProductsPermissions.READ_PRODUCTS_BLOCKED_FILTER}
          >
            <ProductsBlockedFilter
              productsFilters={productsFilters}
              changeProductsFilters={changeProductsFilters}
            />
          </CheckPermissions>

          <ProductsBrandsFilter
            productsFilters={productsFilters}
            changeProductsFilters={changeProductsFilters}
          />
        </div>
      </div>
    </div>
  );
}

export default SelectProductsContentFiltersSidebar;
