import React, { useCallback, useState } from 'react';
import cl from 'classnames';
import size from 'lodash/size';
import take from 'lodash/take';

import { ProductsCategoriesFilterListCategories } from './ProductsCategoriesFilterList.types';
import { IconsEnum } from '../../../../../assets/icons/types';

import {
  ProductsCategoriesFilterListItem,
  ProductsCategoriesFilterListItemCategory
} from './components/ProductsCategoriesFilterListItem';

import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { ButtonHelper } from '../../../../../helpers/buttons/ButtonHelper';
import { Translate } from '../../../../../helpers/Translate';
import { Icon } from '../../../../../helpers/Icon';

import { categoriesKeys } from '../../../../../locales/keys';

const defaultListCategoriesSize = 8;

interface ProductsCategoriesFilterListProps {
  categories: ProductsCategoriesFilterListCategories;
  onSelect: (category: ProductsCategoriesFilterListItemCategory) => void;
  categoriesFetched: boolean;
}

function ProductsCategoriesFilterList({
  categories,
  onSelect,
  categoriesFetched
}: ProductsCategoriesFilterListProps) {
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = useCallback<() => void>(
    () => setShowAll((prevState: boolean) => !prevState),
    []
  );

  const showedCategories = showAll
    ? categories
    : take(categories, defaultListCategoriesSize);

  return (
    <LoadingSkeleton loaded={categoriesFetched}>
      {showedCategories.map((category) => (
        <ProductsCategoriesFilterListItem
          key={category.id}
          category={category}
          onSelect={onSelect}
        />
      ))}

      {size(categories) > defaultListCategoriesSize && (
        <div className="-mx-4">
          <ButtonHelper
            className="bg-transparent hover:bg-gray-200 dark:hover:bg-gray-800 text-gray-900 hover:text-gray-900 dark:text-gray-100 dark:hover:text-gray-100 w-full flex pl-4 pr-2 py-2 text-sm"
            onClick={toggleShowAll}
          >
            <span className="px-2 underline">
              <Translate
                id={showAll ? categoriesKeys.seeLess : categoriesKeys.seeAll}
              />
            </span>
            <div className="ml-2">
              <Icon
                icon={IconsEnum.CHEVRON_DOWN}
                className={cl(
                  'h-5 w-5 text-gray-500',
                  showAll ? 'transform rotate-180' : null
                )}
              />
            </div>
          </ButtonHelper>
        </div>
      )}
    </LoadingSkeleton>
  );
}

export default ProductsCategoriesFilterList;
