import React, { Fragment, useCallback } from 'react';

import {
  CategoryID,
  CategoryLocalizedName,
  CategoryParentID,
  CategoryParentLocalizedName
} from '../../../../../../categories/categoriesTypes';

import {
  ChangeProductsFiltersFunc,
  FetchProductsClearProductsFilters,
  FetchProductsFilterProducts,
  FetchProductsFilters,
  FetchProductsSort,
  FetchProductsTotalCount
} from '../../../../../productsTypes';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { ProductsSortDropdownButton } from '../../../../buttons/ProductsSortDropdownButton';
import { ProductsFavoriteButtonFilter } from '../../../../filters/ProductsFavoriteButtonFilter';
import { ProductsAppliedFilters } from '../../../../ProductsAppliedFilters';

import { PureButtonHelper } from '../../../../../../../helpers/buttons/PureButtonHelper';
import { PureIconButtonHelper } from '../../../../../../../helpers/buttons/PureIconButtonHelper';
import { TextFilter } from '../../../../../../../helpers/filters/TextFilter';
import { Translate } from '../../../../../../../helpers/Translate';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import { productsKeys, words } from '../../../../../../../locales/keys';
import { ProductsPermissions } from '../../../../../productsConstants';

interface SelectProductsContentHeaderCategory {
  id: CategoryID;
  localizedName: CategoryLocalizedName;
  parent?: {
    id: CategoryParentID;
    localizedName: CategoryParentLocalizedName;
  };
}

interface SelectProductsContentHeaderProps {
  parentCategory: SelectProductsContentHeaderCategory;
  setParentCategory: (
    parentCategory: SelectProductsContentHeaderCategory | null
  ) => void;
  productsFilters: FetchProductsFilters;
  changeProductsFilters: ChangeProductsFiltersFunc;
  showFiltersButton: boolean;
  onFiltersOpen: () => void;
  clearProductsFilters: FetchProductsClearProductsFilters;
  filterProducts: FetchProductsFilterProducts;
  currentSort: FetchProductsSort;
  sortProducts: (nextSort: FetchProductsSort) => void;
  productsTotalCount?: FetchProductsTotalCount;
}

function SelectProductsContentHeader({
  parentCategory,
  setParentCategory,
  productsFilters,
  changeProductsFilters,
  showFiltersButton,
  onFiltersOpen,
  clearProductsFilters,
  filterProducts,
  currentSort,
  sortProducts,
  productsTotalCount
}: SelectProductsContentHeaderProps) {
  const handleSearch = useCallback<
    (
      changedFilters: { [name: string]: string },
      removeFilters: string[]
    ) => void
  >(
    ({ name }, removeFilters) =>
      changeProductsFilters({ nameSku: name }, removeFilters),
    [changeProductsFilters]
  );

  return (
    <div className="space-y-6 sm:space-y-4">
      <div className="flex px-4 gap-0.5 items-center">
        {showFiltersButton ? (
          <PureIconButtonHelper
            className="py-1.5 pl-1.5 pr-1.5 sm:pr-3 sm:space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700"
            icon={IconsEnum.FILTER}
            i18nTextClassName="sr-only sm:not-sr-only"
            i18nText={words.filter}
            onClick={onFiltersOpen}
          />
        ) : null}

        <div className="flex items-center w-full max-w-lg">
          <TextFilter
            className="bg-gray-100 dark:bg-gray-800 focus-within:border-gray-300 dark:focus-within:border-gray-600 focus-within:shadow-xl dark:focus-within:bg-gray-700 focus-within:bg-white dark:focus-within:text-gray-300 focus-within:text-gray-600 h-10 relative rounded-lg text-gray-400 border border-transparent w-full"
            inputClassName="bg-transparent block border-transparent dark:focus:placeholder-gray-500 dark:text-gray-200 focus:outline-none focus:ring-4 focus:ring-blue-300 focus:placeholder-gray-300 rounded-lg h-full pl-12 placeholder-gray-400 pr-4 py-2 text-gray-900 w-full"
            name="name"
            value={productsFilters.nameSku || ''}
            i18nPlaceholder={productsKeys.searchProductsByNameOrSku}
            autoComplete="off"
            type="search"
            onChange={handleSearch}
          />
        </div>
      </div>

      <ProductsAppliedFilters
        productsFilters={productsFilters}
        clearProductsFilters={clearProductsFilters}
        filterProducts={filterProducts}
      />

      <div className="flex items-center sm:items-start lg:py-2 flex-col sm:flex-row px-4">
        <div className="flex flex-1 items-center">
          <div className="leading-relaxed my-1.5 text-sm xl:text-base">
            {parentCategory ? (
              <Fragment>
                <PureButtonHelper
                  className="hover:underline"
                  i18nText={productsKeys.plural}
                  onClick={() => setParentCategory(null)}
                />
                {' / '}
              </Fragment>
            ) : null}

            {parentCategory && parentCategory.parent ? (
              <Fragment>
                <PureButtonHelper
                  className="hover:underline"
                  text={parentCategory.parent.localizedName}
                  onClick={() => setParentCategory(parentCategory.parent)}
                />
                {' / '}
              </Fragment>
            ) : null}

            {parentCategory && parentCategory.parent ? (
              <span>
                {parentCategory.localizedName}
                <CheckPermissions
                  action={ProductsPermissions.READ_PRODUCTS_TOTAL_COUNT}
                >
                  {productsTotalCount > 0 ? (
                    <span className="py-1 px-3 text-xs font-medium rounded-full bg-gray-100 dark:bg-gray-600 text-black dark:text-white leading-tight ml-2">
                      {productsTotalCount}
                    </span>
                  ) : null}
                </CheckPermissions>
              </span>
            ) : null}

            {parentCategory && !parentCategory.parent ? (
              <span>
                {parentCategory.localizedName}
                <CheckPermissions
                  action={ProductsPermissions.READ_PRODUCTS_TOTAL_COUNT}
                >
                  {productsTotalCount > 0 ? (
                    <span className="py-1 px-3 text-xs font-medium rounded-full bg-gray-100 dark:bg-gray-600 text-black dark:text-white leading-tight ml-2">
                      {productsTotalCount}
                    </span>
                  ) : null}
                </CheckPermissions>
              </span>
            ) : null}

            {parentCategory ? null : (
              <span className="font-semibold">
                <Translate id={productsKeys.plural} />
                <CheckPermissions
                  action={ProductsPermissions.READ_PRODUCTS_TOTAL_COUNT}
                >
                  {productsTotalCount > 0 ? (
                    <span className="py-1 px-3 text-xs font-medium rounded-full bg-gray-100 dark:bg-gray-600 text-black dark:text-white leading-tight ml-2">
                      {productsTotalCount}
                    </span>
                  ) : null}
                </CheckPermissions>
              </span>
            )}
          </div>
        </div>
        <div className="flex justify-end mt-4 sm:mt-0 space-x-1">
          <ProductsSortDropdownButton
            currentSort={currentSort}
            sortProducts={sortProducts}
          />
          <CheckPermissions
            action={ProductsPermissions.READ_PRODUCTS_FAVORITE_BUTTON_FILTER}
          >
            <ProductsFavoriteButtonFilter
              productsFilters={productsFilters}
              changeProductsFilters={changeProductsFilters}
            />
          </CheckPermissions>
        </div>
      </div>
    </div>
  );
}

export default SelectProductsContentHeader;
