import React, { useCallback } from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';

import { Icon } from '../../../../../helpers/Icon';
import { PureButtonHelper } from '../../../../../helpers/buttons/PureButtonHelper';

import { LifestylesCategoriesFilterItemCategory } from './LifestylesCategoriesFilterItem.types';

interface LifestylesCategoriesFilterItemProps {
  category: LifestylesCategoriesFilterItemCategory;
  onSelect: (category: LifestylesCategoriesFilterItemCategory) => void;
}

function LifestylesCategoriesFilterItem({
  category,
  onSelect
}: LifestylesCategoriesFilterItemProps) {
  const handleCategorySelect = useCallback<() => void>(
    () => onSelect(category),
    [category, onSelect]
  );

  return (
    <div className="-mx-4">
      <div className="flex items-center group relative px-4 py-2 transition-colors ease-in-out duration-150 focus-within:ring-2 focus-within:ring-gray-850 dark:focus-within:ring-gray-100 bg-gray-500 bg-opacity-0 hover:bg-opacity-20">
        <PureButtonHelper
          className="flex-1 px-2 text-sm text-left focus:outline-none focus:ring-none"
          text={category.localizedName}
          onClick={handleCategorySelect}
        />
        <Icon icon={IconsEnum.CHEVRON_RIGHT} />
      </div>
    </div>
  );
}

export default LifestylesCategoriesFilterItem;
