import React, { useCallback, useMemo } from 'react';
import size from 'lodash/size';

import {
  ChangeLifestylesFiltersFunc,
  FetchLifestylesFilters
} from '../../../lifestylesTypes';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { Translate } from '../../../../../helpers/Translate';
import { Checkbox } from '../../../../../helpers/Checkbox';

import { words } from '../../../../../locales/keys';

interface LifestylesMyLibraryFilterProps {
  lifestylesFilters: FetchLifestylesFilters;
  changeLifestylesFilters: ChangeLifestylesFiltersFunc;
}

const name = 'clientIds';

function LifestylesMyLibraryFilter({
  lifestylesFilters,
  changeLifestylesFilters
}: LifestylesMyLibraryFilterProps) {
  const currentUser = useCurrentUser();

  const isMyLib = useMemo<boolean>(() => {
    return size(lifestylesFilters?.clientIds) === 1
      ? lifestylesFilters.clientIds.includes(currentUser.id)
      : false;
  }, [lifestylesFilters.clientIds, currentUser.id]);

  const handleChange = useCallback<() => void>(() => {
    if (isMyLib) {
      changeLifestylesFilters({}, [name]);
    } else {
      changeLifestylesFilters({ [name]: [currentUser.id] });
    }
  }, [changeLifestylesFilters, isMyLib, currentUser.id]);

  return (
    <div className="p-4">
      <div className="flex mb-3">
        <h6 className="font-light uppercase">
          <Translate id={words.myLibrary} />
        </h6>
      </div>

      <Checkbox
        checkboxWrapperClassName="flex items-center"
        i18nLabel={words.showMyLibrary}
        checked={isMyLib}
        onChange={handleChange}
      />
    </div>
  );
}

export default LifestylesMyLibraryFilter;
