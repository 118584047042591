import React, { Fragment, useCallback, useEffect } from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';

import {
  FetchCategoriesScopes,
  FetchCategoriesSortTypes
} from '../../../../categories/categoriesTypes';

import {
  ChangeLifestylesFiltersFunc,
  FetchLifestylesFilterFields
} from '../../../lifestylesTypes';

import {
  FETCH_LIFESTYLE_CATEGORIES_QUERY,
  FetchLifestyleCategoriesQueryResponse
} from '../../../queries/fetchLifestyleCategories.query';

import { usePaginatedCategories } from '../../../../categories/hooks/usePaginatedCategories';

import { LifestylesCategoriesFilterItem } from '../LifestylesCategoriesFilterItem';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';
import { Translate } from '../../../../../helpers/Translate';

import { categoriesKeys } from '../../../../../locales/keys';

import { LifestyleCache } from '../../../LifestyleCache';

import {
  LifestylesCategoriesFilterCategory,
  LifestylesCategoriesFilterSetParentCategory
} from './LifestylesCategoriesFilter.types';

interface LifestylesCategoriesFilterProps {
  parentCategory: LifestylesCategoriesFilterCategory;
  setParentCategory: LifestylesCategoriesFilterSetParentCategory;
  changeLifestylesFilters: ChangeLifestylesFiltersFunc;
}

function LifestylesCategoriesFilter({
  parentCategory,
  changeLifestylesFilters,
  setParentCategory
}: LifestylesCategoriesFilterProps) {
  const {
    categories,
    categoriesError,
    categoriesFetched,
    changeCategoriesFilters
  } = usePaginatedCategories<FetchLifestyleCategoriesQueryResponse>({
    query: FETCH_LIFESTYLE_CATEGORIES_QUERY,
    cacheKey: LifestyleCache.categoriesCacheKey(),
    initialFilters: {
      parentId: parentCategory ? { eq: parentCategory.id } : { isNull: true },
      scope: [FetchCategoriesScopes.LIFESTYLE_GROUPS]
    },
    initialSort: [FetchCategoriesSortTypes.NAME_ASC],
    initialLimit: 1000
  });

  const parentCategoryId = parentCategory?.id;
  const parentCategoryParentId = parentCategory?.parent?.id;

  useEffect(() => {
    changeCategoriesFilters({
      parentId: parentCategoryId ? { eq: parentCategoryId } : { isNull: true }
    });

    if (parentCategoryParentId && parentCategoryId) {
      changeLifestylesFilters(
        {
          [FetchLifestylesFilterFields.LIFESTYLE_CATEGORY_ID]: {
            in: [parentCategoryId]
          },
          [FetchLifestylesFilterFields.LIFESTYLE_PARENT_CATEGORY_IDS]: [
            parentCategoryParentId
          ]
        },
        []
      );
    }

    if (!parentCategoryParentId && parentCategoryId) {
      changeLifestylesFilters(
        {
          [FetchLifestylesFilterFields.LIFESTYLE_PARENT_CATEGORY_IDS]: [
            parentCategoryId
          ]
        },
        [FetchLifestylesFilterFields.LIFESTYLE_CATEGORY_ID]
      );
    }

    if (!parentCategoryParentId && !parentCategoryId) {
      changeLifestylesFilters({}, [
        FetchLifestylesFilterFields.LIFESTYLE_PARENT_CATEGORY_IDS,
        FetchLifestylesFilterFields.LIFESTYLE_CATEGORY_ID
      ]);
    }
  }, [
    parentCategoryId,
    parentCategoryParentId,
    changeCategoriesFilters,
    changeLifestylesFilters
  ]);

  const handleParentCategoryBack = useCallback<() => void>(
    () => setParentCategory(parentCategory.parent),
    [parentCategory, setParentCategory]
  );

  return (
    <div className="p-4">
      <div className="-mx-4 mb-1">
        <div className="flex items-center group relative px-4 py-2">
          {parentCategory ? (
            <Fragment>
              <PureIconButtonHelper
                className="py-2 pl-2 pr-2 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 -mx-1 -my-2"
                icon={IconsEnum.ARROW_LEFT_OUTLINE}
                onClick={handleParentCategoryBack}
              />
              <h6 className="flex-1 font-semibold uppercase px-2">
                {parentCategory.localizedName}
              </h6>
            </Fragment>
          ) : null}
          {parentCategory ? null : (
            <h6 className="flex-1 font-semibold uppercase px-2">
              <Translate id={categoriesKeys.plural} />
            </h6>
          )}
        </div>
      </div>
      <AlertMessage message={categoriesError} />

      <LoadingSkeleton loaded={categoriesFetched}>
        {categories.map((category) => (
          <LifestylesCategoriesFilterItem
            key={category.uuid}
            category={category}
            onSelect={setParentCategory}
          />
        ))}
      </LoadingSkeleton>
    </div>
  );
}

export default LifestylesCategoriesFilter;
