import React, { Fragment, useCallback, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';

import {
  ChangeProductsFiltersFunc,
  FetchProductsLimit,
  FetchProductsSort
} from '../../../productsTypes';
import { ProductsCategoriesFilterCategory } from '../../filters/ProductsCategoriesFilter';
import { SelectProductsContentOnProductsSelect } from './SelectProductsContent.types';

import {
  FETCH_PRODUCTS_QUERY,
  FetchProductsQueryResponse
} from '../../../queries/fetchProducts.query';
import {
  FETCH_PRODUCTS_SET_BY_USER_ID_QUERY,
  FetchProductsSetByUserIdQueryResponse
} from '../../../../productsSets/queries/fetchProductsSetByUserId.query';
import { TogglePreventModalCloseAction } from '../../../../../helpers/modals/modalsTypes';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useMainScrollElement } from '../../../../../app/hooks/useMainScroll';

import { useProductsSetByUserId } from '../../../../productsSets/hooks/useProductsSetByUserId';
import { useProducts } from '../../../hooks/useProducts';

import { SelectedProductsFloatingCounter } from '../../../../selectedProducts/components/SelectedProductsFloatingCounter';
import { ProductsList } from '../../lists/ProductsList';
import { SelectProductsContentFiltersSidebar } from './components/SelectProductsContentFiltersSidebar';
import { SelectProductsContentHeader } from './components/SelectProductsContentHeader';
import { SelectProductsContentSidebar } from './components/SelectProductsContentSidebar';

import { MainScrollWrapper } from '../../../../common/helpers/MainScrollWrapper';
import { LoadMoreButtonHelper } from '../../../../../helpers/buttons/LoadMoreButtonHelper';
import { NoResults } from '../../../../../helpers/NoResults';

import { ProductsSetCache } from '../../../../productsSets/ProductsSetCache';
import { ProductCache } from '../../../ProductCache';

import { words } from '../../../../../locales/keys';
import { ProductsPermissions } from '../../../productsConstants';

const productsCacheKey = ProductCache.indexCacheKey();
const productsCacheKeys = [productsCacheKey];
const productsSetCacheKey = ProductsSetCache.showCacheKey();
const productsSetCacheKeys = [productsSetCacheKey];

interface SelectProductsContentProps {
  initialLimit: FetchProductsLimit;
  onProductsSelect: SelectProductsContentOnProductsSelect;
  togglePreventModalClose: TogglePreventModalCloseAction;
}

function SelectProductsContent({
  initialLimit,
  onProductsSelect,
  togglePreventModalClose
}: SelectProductsContentProps) {
  const {
    products,
    productsError,
    productsTotalCount,
    productsFetched,
    productsFetchingNextPage,
    productsFilters,
    productsIsPlaceholderData,
    hasNextProductsPage,
    updateProductCache,
    loadMoreProducts,
    changeProductsFilters,
    clearProductsFilters,
    sortProducts,
    productsSort,
    filterProducts,
    productsPage,
    productsLimit
  } = useProducts<FetchProductsQueryResponse>({
    cacheKey: productsCacheKey,
    query: FETCH_PRODUCTS_QUERY,
    initialLimit,
    initialFilters: {
      nda: false
    }
  });

  const currentUser = useCurrentUser();

  const {
    productsSet,
    productsSetError,
    productsSetFetched,
    productsSetIsPlaceholderData,
    updateProductsSetCache
  } = useProductsSetByUserId<FetchProductsSetByUserIdQueryResponse>({
    cacheKey: ProductsSetCache.showCacheKey(),
    query: FETCH_PRODUCTS_SET_BY_USER_ID_QUERY,
    userId: currentUser.get('id'),
    options: {
      onSuccess: (data) =>
        data?.productsSetByUserId?.selectedProducts
          ? onProductsSelect?.(data?.productsSetByUserId?.selectedProducts)
          : undefined
    }
  });

  const { mainScrollElement } = useMainScrollElement();

  const handleChangeProductsFilters = useCallback<ChangeProductsFiltersFunc>(
    (changedFilters, removeFilters) => {
      mainScrollElement?.scrollTo({
        top: 0
      });

      return changeProductsFilters(changedFilters, removeFilters);
    },
    [changeProductsFilters, mainScrollElement]
  );

  const [isSelectedProductsSidebarOpen, setIsSelectedProductsSidebarOpen] =
    useState(false);

  const handleSelectedProductsSidebarOpen = useCallback<() => void>(
    () => setIsSelectedProductsSidebarOpen(true),
    [setIsSelectedProductsSidebarOpen]
  );

  const handleSelectedProductsSidebarClose = useCallback<() => void>(
    () => setIsSelectedProductsSidebarOpen(false),
    [setIsSelectedProductsSidebarOpen]
  );

  const [isFiltersSidebarOpen, setIsFiltersSidebarOpen] = useState(true);

  const handleFiltersSidebarOpen = useCallback<() => void>(
    () => setIsFiltersSidebarOpen(true),
    [setIsFiltersSidebarOpen]
  );

  const handleFiltersSidebarClose = useCallback<() => void>(
    () => setIsFiltersSidebarOpen(false),
    [setIsFiltersSidebarOpen]
  );

  const [parentCategory, setParentCategory] =
    useState<ProductsCategoriesFilterCategory | null>(null);

  const selectedProductsCount = size(productsSet?.selectedProducts);

  return (
    <Fragment>
      {isFiltersSidebarOpen ? (
        <SelectProductsContentFiltersSidebar
          parentCategory={parentCategory}
          setParentCategory={setParentCategory}
          productsFilters={productsFilters}
          changeProductsFilters={handleChangeProductsFilters}
          onClose={handleFiltersSidebarClose}
        />
      ) : null}
      <MainScrollWrapper>
        <div className="flex-1 z-0">
          <div className="pt-6 pb-8">
            <div className="flex flex-col space-y-8">
              <SelectProductsContentHeader
                parentCategory={parentCategory}
                setParentCategory={setParentCategory}
                productsFilters={productsFilters}
                changeProductsFilters={handleChangeProductsFilters}
                showFiltersButton={!isFiltersSidebarOpen}
                onFiltersOpen={handleFiltersSidebarOpen}
                clearProductsFilters={clearProductsFilters}
                filterProducts={filterProducts}
                sortProducts={sortProducts}
                currentSort={productsSort as FetchProductsSort}
                productsTotalCount={productsTotalCount}
              />
              {productsFetched && isEmpty(products) ? (
                <NoResults
                  noResultsI18nText={words.thereAreNoMatchesForSelectedFilters}
                />
              ) : (
                <ProductsList
                  products={products}
                  productsFetched={productsFetched}
                  productsSetFetched={productsSetFetched}
                  productsError={productsError}
                  productsSetError={productsSetError}
                  productsIsPlaceholderData={productsIsPlaceholderData}
                  productsSetIsPlaceholderData={productsSetIsPlaceholderData}
                  productsTotalCount={productsTotalCount}
                  productsSet={productsSet}
                  productsCacheKeys={productsCacheKeys}
                  productsSetCacheKeys={productsSetCacheKeys}
                  productsFilters={productsFilters}
                  changeProductsFilters={handleChangeProductsFilters}
                  onSelectedProductsSidebarOpen={
                    handleSelectedProductsSidebarOpen
                  }
                  onSelectedProductsSidebarClose={
                    handleSelectedProductsSidebarClose
                  }
                  updateProductsSetCache={updateProductsSetCache}
                  updateProductCache={updateProductCache}
                  onProductsSelect={onProductsSelect}
                  togglePreventModalClose={togglePreventModalClose}
                />
              )}
              {productsSetFetched && productsSet ? (
                <SelectedProductsFloatingCounter
                  productsSet={productsSet}
                  productsSetCacheKey={productsSetCacheKey}
                  isOpen={isSelectedProductsSidebarOpen}
                  onOpen={handleSelectedProductsSidebarOpen}
                  onClose={handleSelectedProductsSidebarClose}
                  updateProductsSetCache={updateProductsSetCache}
                  onProductsSelect={onProductsSelect}
                />
              ) : null}
              {hasNextProductsPage && (
                <LoadMoreButtonHelper
                  action={
                    ProductsPermissions.READ_PRODUCTS_LOAD_MORE_BUTTON_ITEMS_COUNT
                  }
                  itemsTotalCount={productsTotalCount}
                  page={productsPage}
                  limit={productsLimit}
                  loadMoreItems={loadMoreProducts}
                  itemsFetchingNextPage={productsFetchingNextPage}
                />
              )}
            </div>
          </div>
        </div>
      </MainScrollWrapper>
      {isSelectedProductsSidebarOpen ? (
        <SelectProductsContentSidebar
          productsSet={productsSet}
          productsSetFetched={productsSetFetched}
          productsSetError={productsSetError}
          productsSetIsPlaceholderData={productsSetIsPlaceholderData}
          productsCacheKeys={productsCacheKeys}
          productsSetCacheKeys={productsSetCacheKeys}
          updateProductsSetCache={updateProductsSetCache}
          onClose={handleSelectedProductsSidebarClose}
          togglePreventModalClose={togglePreventModalClose}
          selectedProductsCount={selectedProductsCount}
        />
      ) : null}
    </Fragment>
  );
}

export default SelectProductsContent;
