import React, { useCallback, useMemo } from 'react';
import size from 'lodash/size';

import {
  ChangeProductsFiltersFunc,
  FetchProductsFilters
} from '../../../productsTypes';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { Translate } from '../../../../../helpers/Translate';
import { Checkbox } from '../../../../../helpers/Checkbox';

import { words } from '../../../../../locales/keys';

interface ProductsMyLibraryFilterProps {
  productsFilters: FetchProductsFilters;
  changeProductsFilters: ChangeProductsFiltersFunc;
}

const name = 'clientIds';

function ProductsMyLibraryFilter({
  productsFilters,
  changeProductsFilters
}: ProductsMyLibraryFilterProps) {
  const currentUser = useCurrentUser();

  const isMyLib = useMemo<boolean>(() => {
    return size(productsFilters?.clientIds) === 1
      ? productsFilters.clientIds.includes(currentUser.id)
      : false;
  }, [currentUser.id, productsFilters.clientIds]);

  const handleChange = useCallback<() => void>(() => {
    if (isMyLib) {
      changeProductsFilters({}, [name]);
    } else {
      changeProductsFilters({ [name]: [currentUser.id] });
    }
  }, [changeProductsFilters, isMyLib, currentUser.id]);

  return (
    <div className="p-4">
      <div className="flex mb-3">
        <h6 className="font-light uppercase">
          <Translate id={words.myLibrary} />
        </h6>
      </div>

      <Checkbox
        checkboxWrapperClassName="flex items-center"
        i18nLabel={words.showMyLibrary}
        checked={isMyLib}
        onChange={handleChange}
      />
    </div>
  );
}

export default ProductsMyLibraryFilter;
